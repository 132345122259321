import React from 'react'
import { graphql } from 'gatsby'

import { ContactPage } from '../components/ContactPage'
import { ISiteCopy } from '../@types'

interface IProps {
  data: { sanitySiteCopy: Pick<ISiteCopy, '_rawContactPageText'> }
}

export default function index({ data }: IProps) {
  return <ContactPage contactPageText={data.sanitySiteCopy._rawContactPageText} />
}

export const query = graphql`
    query {
        sanitySiteCopy {
            _rawContactPageText 
        }
    }
`

