import React from 'react'

import contactIcon from '../../../static/svg/contact-book.svg'

import { PageTitles } from '../../vars'
import { Layout } from '../common/Layout'
import { Container } from './styles'
import { IBlockText } from '../../@types'
import BlockContent from '@sanity/block-content-to-react'
import { SocialLinks } from '../common/SocialLinks'

interface IProps {
  contactPageText: IBlockText[]
}

export function ContactPage({ contactPageText }: IProps) {
  return (
    <Layout currentPageTitle={PageTitles.Contact} darkBg={false}>
      <Container>
        <header>
          <h1>
            {PageTitles.Contact} <img src={contactIcon} alt='People icon' />
          </h1>
        </header>

        <BlockContent blocks={contactPageText} />

        <p>Also, here are some links where you can find me:</p>
        <SocialLinks />
      </Container>
    </Layout>
  )
}
