import styled from 'styled-components'

import { cardEnterAnim } from '../../globalStyles'
import { rhythm } from '../../utils/typography'
import { mediaQueries } from '../../vars'

export const Container = styled.section`
  animation: ${cardEnterAnim} 0.6s 0.2s backwards;
  position: relative;
  background: #ffffffdd;
  padding: ${rhythm(1)};
  border-radius: 10px;
  width: fit-content;
  margin: ${rhythm(1)} auto;

  h1 {
    font-size: 2rem;
    margin-bottom: ${rhythm(2)};
    display: flex;
    align-items: center;

    img {
      margin-bottom: ${rhythm(1 / 3)};
      margin-left: ${rhythm(1 / 3)};
    }

    @media ${mediaQueries.general.handheld} {
      font-size: 1.3rem;
      line-height: 1.9rem;
      margin-bottom: ${rhythm(1)};
    }
  }
`
